import React from "react";
import { NavLink, useMatch } from "react-router-dom";
import SvgIcon from "@/components/icons/SvgIcon";

export default function MobileMenuItem({ path, label, icon, onClick }) {
  const match = useMatch({
    path: path,
    strict: true,
    sensitive: true,
    exact: true,
  });
  return (
    <NavLink to={path} key={label} onClick={onClick}>
      <div className="py-2 text-xl flex items-center">
        <div className={`w-1 h-6  ${match ? "bg-yellow-600" : ""}`}></div>
        <SvgIcon svgD={icon} classes="h-6 w-6 inline ml-7 mr-4" />{" "}
        <span>{label}</span>
      </div>
    </NavLink>
  );
}
