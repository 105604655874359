const menuItems = [
  {
    label: "Home",
    path: "/",
    exact: true,
    icon: [
      "M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6",
    ],
  },
  // {
  //   label: "About",
  //   path: "/about",
  //   exact: false,
  //   icon: ["M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"],
  // },
  // {
  //   label: "Contact",
  //   path: "/contact",
  //   exact: false,
  //   icon: [
  //     "M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z",
  //   ],
  // },
];

export default menuItems;
