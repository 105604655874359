import React from "react";
import Header from "@/layouts/header/Header";

export default function MainLayout({ children }) {
  return (
    <div className="coverImage bg-cover bg-center	min-h-screen bg-indigo-100 max-w-screen-xl mx-auto">
        <Header />
      <div className="h-full p-4">{children}</div>
      <p className="fixed bottom-1 right-2 text-gray-500 text-xs mb-1">
        Photo by <a href="https://unsplash.com/@asoggetti?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Alessio Soggetti</a> on <a href="https://unsplash.com/@asoggetti?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
      </p>
    </div>
  );
}
