import React from "react";

export default function Home() {
  return (
    <div className="flex h-96 w-full items-center justify-center">
      <div className="rounded-lg bg-yellow-600 bg-opacity-20 p-8 ">
        <h1 className="text-4xl md:text-6xl">www.clxrk.com</h1>
      </div>
    </div>
  );
}
