import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainLayout from "@/layouts/MainLayout";
import Home from "@/pages/Home";
// import Contact from "@/pages/Contact";
// import About from "@/pages/About";
// import Register from "@/pages/auth/Register";
// import Login from "@/pages/auth/Login";

export default function App() {
  return (
    <Router>
      <MainLayout>
      <Routes>          
        <Route path="/" exact element={<Home />} />
        {/* <Route path="/about" exact element={<About />} />
        <Route path="/contact" exact element={<Contact />} /> */}
      </Routes>
      </MainLayout>
    </Router>
);
}

