import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "@/assets/images/logo.png";
import menuItems from "@/constants/menu-items";
import DesktopMenuItem from "@/layouts/menu/DesktopMenuItem";
import MobileMenuItem from "@/layouts/menu/MobileMenuItem";
// import SvgIcon from "@/components/icons/SvgIcon";

export default function Header() {
  const [showSideMenu, setShowSideMenu] = useState(false);

  const toggleSideMenu = () => {
    setShowSideMenu((showSideMenu) => !showSideMenu);
  };

  return (
    <>
      <nav className="bg-gray-600 px-8 bg-opacity-60">
        <div className="min-w-screen flex items-center">
          <div className="mr-auto flex-1">
            <button onClick={toggleSideMenu} className="md:hidden">
              <svg
                className="w-10 h-10"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
            <div className="hidden md:flex">
              {menuItems.map((item) => {
                return (
                  <DesktopMenuItem
                    path={item.path}
                    label={item.label}
                    key={item.label}
                  />
                );
              })}
            </div>
          </div>
          <div>
            <NavLink to="/">
              <img className="w-12 h-12 my-2" src={Logo} alt="Logo" />
            </NavLink>
          </div>
          <div className="ml-auto flex-1 text-right">

                {/* <button onClick={handleLogout}>Sign out</button>
                <SvgIcon
                  svgD="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  classes="h-10 w-10 inline"
                /> */}

                {/* <NavLink className="mr-2 md:mr-4" to="/login">
                  Login
                </NavLink>
                <NavLink
                  to="/register"
                  className="bg-yellow-600 rounded p-2 text-white"
                >
                  Register
                </NavLink> */}

          </div>
        </div>
      </nav>
      <div
        className={`md:hidden transform left-0 w-56 bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 ${
          showSideMenu ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="flex flex-col mt-4">
          {menuItems.map((item) => {
            return (
              <MobileMenuItem
                path={item.path}
                label={item.label}
                icon={item.icon}
                key={item.label}
                onClick={toggleSideMenu}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}
