import React from "react";
import { NavLink, useMatch } from "react-router-dom";

export default function DesktopMenuItem({ path, label }) {
  const match = useMatch({
    path: path,
    strict: true,
    sensitive: true,
    exact: true,
  });
  return (
    <div
      className={`mr-2 p-2 border-b-4 text-2xl px-1 font-light leading-none rounded border-transparent hover:border-yellow-600 focus:bg-yellow-600 ${
        match ? "border-yellow-600" : ""
      }`}
    >
      <NavLink to={path} key={label}>
        {label}
      </NavLink>
    </div>
  );
}
